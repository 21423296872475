import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

import { ApolloLink } from 'apollo-link'

import { createConsumer } from '@rails/actioncable'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'

export const APOLLO_URL = 'https://app.birdweather.com/graphql'
export const CABLE_URL = 'https://cable.birdweather.com/cable'

const cable = createConsumer(CABLE_URL)
const cableLink = new ActionCableLink({ cable })

const httpLink = createHttpLink({ uri: APOLLO_URL })

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const link = ApolloLink.split(hasSubscriptionOperation, cableLink, httpLink)

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
})

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
})
