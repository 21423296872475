<template>
  <div class="detection">
    <SpeciesThumbnail class="d-block medium rounded-circle" :species="detection.species" />

    <div class="detection-main">
      <div class="detection-info flex-grow-1">
        <p class="mb-0">
          {{ detection.species.commonName }} <em class="text-muted">({{ detection.species.scientificName }})</em><br />
        </p>

        <p class="small text-muted mb-0" v-if="detection.station">
          {{ detection.station.name }}
        </p>
      </div>

      <DetectionScore class="flex-shrink-0" :detection="detection" :tooltip="false" />
    </div>
  </div>
</template>

<script>
import DetectionScore from '@/components/DetectionScore.vue'
import SpeciesThumbnail from '@/components/SpeciesThumbnail.vue'

import { formatTimestamp, formatDate, timeSince } from '@/lib/formatting'

export default {
  props: {
    detection: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatTimestamp,
    formatDate,
    timeSince
  },

  components: {
    DetectionScore,
    SpeciesThumbnail
  }
}
</script>
