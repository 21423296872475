<template>
  <Transition name="fade">
    <LoadingStatus v-if="loading" status="Connecting..." :indeterminate="true" />
  </Transition>
</template>

<script>
import Detection from '@/components/Detection.vue'
import LoadingStatus from '@/components/controls/LoadingStatus.vue'

import NewDetectionsQuery from '@/queries/NewDetections.graphql'

import { render, h } from 'vue'

export default {
  inject: ['mapbox', 'map'],

  data () {
    return {
      active: true,
      loading: true,

      detections: {},
      activeMarkers: {}
    }
  },

  mounted () {
    document.addEventListener('visibilitychange', this.setActiveStatusFromDocumentVisibility)

    if (this.map._loaded) {
      this.initializeMapLayers()
    } else {
      this.map.on('load', () => {
        this.initializeMapLayers()
      })
    }

    this.popup = new this.mapbox.Popup({
      anchor: 'top',
      offset: [0, 16],
      closeButton: false,
      closeOnClick: false,
      maxWidth: '350px'
    })
  },

  activated () {
    this.active = true
    this.toggleLayer('live-detections-dots', true)

    document.addEventListener('visibilitychange', this.setActiveStatusFromDocumentVisibility)
  },

  deactivated () {
    this.active = false
    this.toggleLayer('live-detections-dots', false)

    for (const id in this.activeMarkers) {
      this.removeMarker(id)
    }

    document.removeEventListener('visibilitychange', this.setActiveStatusFromDocumentVisibility)
  },

  methods: {
    initializeMapLayers () {
      this.map.addSource('live-detections', {
        type: 'geojson',
        data: this.detectionsGeoJSON
      })

      this.map.addLayer({
        id: `live-detections-dots`,
        source: 'live-detections',
        type: 'circle',
        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],

            // Zoom is 2 -> circle radius will be 1px - 4px
            2, ['interpolate', ['linear'], ['get', 'detections'],
              1, 1, // 1 detection => 1px
              20, 4 // 20 detections => 4px
            ],

            // Zoom is 9 -> circle radius will be 6px - 24px
            9, ['interpolate', ['linear'], ['get', 'detections'],
              1, 6, // 1 detection => 6px
              20, 24 // 20 detections => 24px
            ]
          ],
          'circle-color': ['get', 'color']
        }
      })

      setInterval(() => this.updateMapSource(), 5000)
    },

    newDetection (detection) {
      const key = `${detection.coords.lon},${detection.coords.lat}`

      // this.detections[key] ||= []
      // this.detections[key].push(detecti3on)

      this.detections[key] = [detection]

      if (this.active && !this.existingActiveMarker(detection)) {
        const marker = this.createMarker(detection)
        marker.addTo(this.map)

        this.activeMarkers[detection.id] = {
          detection: detection,
          marker: marker
        }

        this.setMarkerTimeout(detection.id)
      }

      this.$emit('newDetection', detection)
    },

    updateMapSource () {
      this.map.getSource('live-detections').setData(this.detectionsGeoJSON)
    },

    toggleLayer (layer, visible) {
      if (this.map.getLayer(layer)) {
        const visibility = visible ? 'visible' : 'none'
        this.map.setLayoutProperty(layer, 'visibility', visibility)
      }
    },

    existingActiveMarker (detection) {
      return Object.values(this.activeMarkers).some(m => {
        m.detection.coords == detection.coords
      })
    },

    setMarkerTimeout (id) {
      this.clearMarkerTimeout(id)

      const timeout = setTimeout(() => {
        this.removeMarkerAnimated(id)
      }, 5000)

      this.activeMarkers[id].timeout = timeout
    },

    clearMarkerTimeout (id) {
      if (this.activeMarkers[id].timeout) {
        clearTimeout(this.activeMarkers[id].timeout)
        this.activeMarkers[id].timeout = null
      }
    },

    createMarker (detection) {
      const coords = [detection.coords.lon, detection.coords.lat]

      const wrapper = document.createElement('div')
      const el = document.createElement('div')

      el.style.backgroundImage = `url(${detection.species.thumbnailUrl})`
      el.style.borderColor = detection.species.color

      el.classList.add('map-species-marker', 'animate', 'pop')

      wrapper.append(el)

      wrapper.addEventListener('mouseenter', () => {
        el.classList.remove('pop', 'out')
        el.classList.add('focus')

        this.showPopup(detection)
        this.clearMarkerTimeout(detection.id)
      })

      wrapper.addEventListener('mouseleave', () => {
        el.addEventListener('animationend', () => {
          el.classList.remove('focus', 'out')
        }, { once: true })

        el.classList.add('out')

        this.popup.remove()
        this.setMarkerTimeout(detection.id)
      })

      wrapper.addEventListener('click', () => {
        this.$emit('clickDetection', detection)

        this.map.flyTo({ center: coords, zoom: 7, speed: 0.7 })
      })

      return new this.mapbox.Marker(wrapper).setLngLat(coords)
    },

    removeMarkerAnimated (id) {
      const marker = this.activeMarkers[id]?.marker

      if (marker) {
        const el = marker.getElement().querySelector('div')

        el.addEventListener('animationend', () => {
          this.removeMarker(id)
        }, { once: true })

        el.classList.remove('pop')
        el.classList.add('fade-out')
      }
    },

    removeMarker (id) {
      const marker = this.activeMarkers[id].marker
      marker.remove()

      delete this.activeMarkers[id]
    },

    showPopup (detection) {
      const coords = [detection.coords.lon, detection.coords.lat]

      const target = document.createElement('div')
      render(h(Detection, { detection: detection }), target)

      this.popup
        .setLngLat(coords)
        .setHTML(target.outerHTML)
        .addClassName('detection-marker-popup')
        .addTo(this.map)
    },

    setActiveStatusFromDocumentVisibility () {
      this.active = !document.hidden
    }
  },

  computed: {
    detectionsGeoJSON () {
      return {
        type: 'FeatureCollection',
        features: Object.values(this.detections).map(detections => {
          const latest = detections[detections.length - 1]

          return {
            type: 'Feature',
            properties: {
              color: latest.species.color,
              detections: detections.length
            },
            geometry: {
              type: 'Point',
              coordinates: [latest.coords.lon, latest.coords.lat]
            }
          }
        })
      }
    }
  },

  apollo: {
    $subscribe: {
      newDetection: {
        query: NewDetectionsQuery,

        result ({ data }) {
          this.loading = false

          if (data.newDetection) {
            const detection = data.newDetection.detection
            this.newDetection(detection)
          }
        }
      }
    }
  },

  components: {
    LoadingStatus
  }
}
</script>
